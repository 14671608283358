<template>
  <v-dialog
    v-model="dialogAdicionarHistorico"
    @click:outside="$emit('update:dialogAdicionarHistorico', false)"
    @keydown.esc="$emit('update:dialogAdicionarHistorico', false)"
    width="500"
    scrollable
  >
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-4">
        Adicionar Tempo
        <v-spacer></v-spacer>
        <v-icon @click="$emit('update:dialogAdicionarHistorico', false)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-6 py-8">
        <v-row>
          <v-col cols="12" md="12">
            <v-select
              v-model="selectedColaborador"
              :items="colaboradores"
              :loading="loading"
              label="Colaborador"
              item-text="nome"
              item-value="id"
              hide-details
              return-object
              dense
            >
            </v-select>
          </v-col>

          <!-- Data -->
          <v-col cols="12" class="mt-2">
            <DataField
              label="Data"
              :data_sync.sync="data"
              obrigatorio
            />
          </v-col>

          <!-- Hora de Postagem -->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="hora_inicio"
              label="Hora Início"
              type="time"
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Hora de Postagem -->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="hora_fim"
              label="Hora Fim"
              type="time"
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Nota -->
          <v-col cols="12">
            <v-textarea v-model="note" label="Nota" dense rows="2"></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-btn outlined @click="$emit('update:dialogEditarHistorico', false)">
          Voltar</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="adicionarTempo">adicionar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchClienteColaboradoresAtividade } from "@/api/gestor/clientes.js";
import { postAtividadeAdicionarTempo } from "@/api/gestor/atividades_clientes.js";

export default {
  name: "AdicionarHistoricoTempo",

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
  },

  props: {
    dialogAdicionarHistorico: {
      type: Boolean,
    },
    atividade_id: {
      type: [Number, String],
    },
  },

  data() {
    return {
      colaboradores: [],
      selectedColaborador: null,
      loading: true,
      data: null,
      hora_inicio: "00:00",
      hora_fim: "00:00",
      note: "",
      dia_semana: "",
    };
  },

  computed: {
    disableAddTime() {
      let result = false;
      if (!this.data || !this.hora_inicio || !this.hora_fim) {
        result = true;
      }
      return result;
    },
  },

  watch: {
    selectedColaborador() {
      if (this.selectedColaborador) {
        this.jornada_trabalho = this.selectedColaborador.jornada_trabalho;
      }
    },

  },

  methods: {
    getClienteColaborador() {
      this.loading = true;
      return fetchClienteColaboradoresAtividade(this.atividade_id)
        .then((response) => {
          this.colaboradores = response;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    adicionarTempo() {
      this.loading = true;
      let tempo = {};
      tempo.atividade_id = this.atividade_id;
      tempo.colaborador_id = this.selectedColaborador.id;
      tempo.data = this.data;
      tempo.hora_inicio = this.hora_inicio;
      tempo.hora_fim = this.hora_fim;
      tempo.note = this.note;

      postAtividadeAdicionarTempo(tempo)
        .then((response) => {
          if (response.status === 201) {
            if (response.data.result == "success") {
              this.$emit("update:dialogAdicionarHistorico", false);
              this.$emit("fetch-historico");
            }
            if (response.data.result == "failed") {
              this.$toast.error(response.data.message);
            }
          }
        })
        .catch((error) => {
          if (error.response.status == 406) {
            this.$toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.getClienteColaborador();
  },
};
</script>

<style></style>
